import {
  Divider,
  List,
  ListItem,
  ListItemIcon,
  Typography,
  Link,
} from "@material-ui/core";
import React, { useState } from "react";
import { CONFIG } from "../../../config/config";
import "./MyFavourites.css";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import CircleIcon from "@mui/icons-material/Circle";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import Tools from "../Tools/Tools";
import { useLocation } from "react-router-dom";
import styled from "styled-components";

import DoneIcon from "@mui/icons-material/Done";

const data = {
  key_points: {
    "Gen-AI Powered Insights":
      "Use AI for real-time data and personalized insights to optimize pricing and promotions.",
    "Unified RGM Platform":
      "Centralize all RGM functions for comprehensive, multi-lever optimization.",
    "Seamless E2E Integration":
      "Ensure cohesive planning and execution with seamless RGM lever & tech stack integration.",
    "Enhanced Decision-Making":
      " Enable faster, data-driven decisions with actionable insights.",
  },
  header:`Discover the power of AI-enabled Next-Gen Revenue Growth Management!
            Transform your pricing, promotions, and product assortment
            strategies with advanced analytics and real-time insights.`
};

const MyFavourites = () => {
  const [value, setValue] = useState(0);
  const moveBehind = () => {
    value === -90 * (CONFIG.CARD_SLIDE_DATA.length - 2)
      ? setValue(0)
      : setValue(value - 90);
  };
  const moveAhead = () => {
    value === 0
      ? setValue(-90 * (CONFIG.CARD_SLIDE_DATA.length - 2))
      : setValue(value + 90);
  };

  return (
    <div className="my-favourites">
      <div className="component">
        <Typography
          variant="h4"
          gutterBottom
          style={{ fontWeight: 600, fontFamily: "Montserrat" }}
        >
          <span>Key Business Metrics</span>
        </Typography>
        <div className="promotion-data metric">
          {CONFIG.PROMOTION_DATA.map((p_d, i) => {
            return (
              <div
                className="card"
                key={i}
                style={{
                  display: "flex",
                  width: "25%",
                  justifyContent: "space-between",
                  padding: "10px",
                }}
              >
                <div>
                  <Typography
                    variant="body1"
                    gutterBottom
                    style={{ color: "#595959", fontFamily: "Montserrat" }}
                  >
                    {p_d.name}
                  </Typography>
                  <Typography
                    variant="h3"
                    className="percentage"
                    gutterBottom
                    style={{
                      fontWeight: 700,
                      fontSize: "24px",
                      color: "#000000",
                      fontFamily: "Montserrat",
                    }}
                  >
                    {p_d.percentage}
                  </Typography>
                  <div className="comparision-percentage">
                    <ArrowUpwardIcon className="arrow-up-icon" /> &nbsp;
                    <Typography
                      variant="button"
                      display="block"
                      gutterBottom
                      style={{
                        fontSize: "20px",
                        color: "#12A7AE",
                        fontWeight: 700,
                        margin: 0,
                        fontFamily: "Montserrat",
                      }}
                    >
                      {p_d.comparision_percentage}&nbsp;
                    </Typography>
                    <Typography
                      variant="button"
                      display="block"
                      gutterBottom
                      style={{
                        fontSize: "20px",
                        color: "rgba(89, 89, 89, 0.8)",
                        margin: 0,
                        fontFamily: "Montserrat",
                      }}
                    >
                      {p_d.comparision_value}
                    </Typography>
                  </div>
                </div>
                {i !== CONFIG.PROMOTION_DATA.length - 1 ? (
                  <Divider orientation="vertical" variant="middle" flexItem />
                ) : null}
              </div>
            );
          })}
        </div>
      </div>
      <div className="top-profile">
        <div className="left">
          <Typography
            variant="h5"
            style={{ fontWeight: 600, fontFamily: "Montserrat" }}
          >
           {data.header}
          </Typography>
          {Object.keys(data.key_points).map((key, i) => {
            return (
              <div
                className="key-point"
                key={i}
                style={{ display: "flex", marginTop: "10px" }}
              >
                <DoneIcon style={{ color: "rgba(61, 213, 200, 1)" }} />
                <Typography
                  variant="subtitle2"
                  gutterBottom
                  style={{
                    marginLeft: "10px",
                    color: "#595959",
                    fontFamily: "Montserrat",
                  }}
                >
                  <b> {key}: </b> {data.key_points[key]}
                </Typography>
              </div>
            );
          })}
        </div>
        <div className="right">
          <div className="video-contianer">
            <iframe
              width="100%"
              style={{minWidth:"653px"}}
              height="370"
              src="https://www.youtube.com/embed/0eCaob9r7SA"
              title="Hubble: AI Enabled Next Generation RGM Demo"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerpolicy="strict-origin-when-cross-origin"
              allowfullscreen
            ></iframe>
          </div>
        </div>
      </div>
      <div className="recent-scenarios">
        <div className="component scene">
          <Typography
            variant="h6"
            gutterBottom
            style={{
              fontWeight: 600,
              color: "#2D2A2B",
              fontFamily: "Montserrat",
            }}
          >
            Recently Created Scenarios
          </Typography>
          <Typography
            variant="caption"
            display="block"
            gutterBottom
            style={{ color: "rgba(89, 89, 89, 0.8)", fontFamily: "Montserrat" }}
          >
            Access your most important links at one go
          </Typography>
          <div className="glider">
            {CONFIG.CARD_SLIDE_DATA.map((card, i) => {
              return (
                <div
                  key={i}
                  className="glide"
                  style={{
                    backgroundColor: CONFIG.CARD_SLIDE_COLORS[i],
                    transform: `translateX(${value}%)`,
                  }}
                >
                  <Typography
                    variant="h6"
                    gutterBottom
                    style={{
                      fontWeight: 600,
                      color: "#2D2A2B",
                      fontFamily: "Montserrat",
                    }}
                  >
                    {card.header}
                  </Typography>
                  <List>
                    {card.data.map((c_d, j) => {
                      return (
                        <ListItem key={j}>
                          <div className="pointer-flex">
                            <div
                              className="pointers"
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <ListItemIcon className="bullet-icon">
                                <CircleIcon
                                  style={{
                                    fontSize: "0.5em",
                                    color: "black",
                                    marginRight: "5px",
                                  }}
                                />
                              </ListItemIcon>
                              {c_d.point}
                            </div>
                            <div className="view-link">
                              <Link
                                href={c_d.link}
                                color="inherit"
                                style={{
                                  color: "#2D2A2B",
                                  fontWeight: 550,
                                  textDecoration: "underline",
                                  fontFamily: "Montserrat",
                                }}
                              >
                                View Results
                              </Link>
                            </div>
                          </div>
                        </ListItem>
                      );
                    })}
                  </List>
                </div>
              );
            })}
          </div>
          <div style={{ display: "flex", justifyContent: "right" }}>
            <ArrowRightAltIcon
              id="moveBehind"
              onClick={value === 0 ? null : moveAhead}
              style={{
                color: value === 0 ? "grey" : "black",
                fontSize: "30px",
              }}
            />
            <ArrowRightAltIcon
              id="moveAhead"
              onClick={value === -90 ? null : moveBehind}
              style={{
                color: value === -90 ? "grey" : "black",
                fontSize: "30px",
              }}
            />
          </div>
        </div>
      </div>
      <Tools />
    </div>
  );
};

export default MyFavourites;
